import React, {useEffect, useState} from 'react';
import {getCookie} from 'library/cookie/get';
import {CookiesNames} from 'library/cookie/cookiesNames';
import {
    Paper,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material';

interface UnmappedBranchesResponse {
    cinemaBranchLinks: {
        cinemaBranchUrl: string
    }[]
}

export const KinomaniakMappingBranchesScreen = () => {
    const [unmappedBranches, setUnmappedBranches] = useState<
        UnmappedBranchesResponse['cinemaBranchLinks'] | undefined
    >(undefined);
    const loadUnmappedBranches = async () => {
        const response = await fetch(
            '/admin-api/cinema/kinomaniak-mapping/get-unmapped-cinema-branches',
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${getCookie(CookiesNames.JWT_KEY)}`,
                    'Content-Type': 'application/json',
                },
            },
        );
        const unmappedBranchesJson: UnmappedBranchesResponse = await response.json();
        setUnmappedBranches(unmappedBranchesJson.cinemaBranchLinks);
    };

    useEffect(() => {
        loadUnmappedBranches();
    }, []);

    if (unmappedBranches === undefined) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Movie link</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {unmappedBranches.map(({cinemaBranchUrl}) => (
                            <TableRow
                                key={cinemaBranchUrl}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <a href={cinemaBranchUrl} target="_blank">
                                        {cinemaBranchUrl}
                                    </a>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
