import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {JobsScreen} from 'screens/jobs/JobsScreen';
import {HomepageScreen} from 'screens/homepage/HomepageScreen';
import {UploadsScreen} from 'screens/uploads/UploadsScreen';
import {MovieNamesScreen} from 'screens/proofRead/MovieNamesScreen';
import {MovieDescriptionScreen} from 'screens/proofRead/MovieDescriptionScreen';
import {KinomaniakMappingMovieScreen} from 'screens/cinema/KinomaniakMappingMovieScreen';
import {KinomaniakMappingBranchesScreen} from 'screens/cinema/KinomaniakMappingBranchesScreen';
import {UserProofreadDescriptionStatsScreen} from 'screens/proofRead/UserProofreadDescriptionStatsScreen';
import App from './App';

const router = createBrowserRouter(
    [
        {
            path: '/',
            element: <App />,
            children: [
                {
                    path: '/',
                    element: <HomepageScreen />,
                },
                {
                    path: 'uploads',
                    element: <UploadsScreen />,
                },
                {
                    path: 'jobs',
                    element: <JobsScreen />,
                },
                {
                    path: 'proof-read/movie-names',
                    element: <MovieNamesScreen />,
                },
                {
                    path: 'proof-read/descriptions',
                    element: <MovieDescriptionScreen />,
                },
                {
                    path: 'proof-read/user-proofread-description-stats',
                    element: <UserProofreadDescriptionStatsScreen />,
                },
                {
                    path: 'cinema/kinomaniak-movies-mapping',
                    element: <KinomaniakMappingMovieScreen />,
                },
                {
                    path: 'cinema/kinomaniak-branches-mapping',
                    element: <KinomaniakMappingBranchesScreen />,
                },
            ],
        },
    ],
    {
        basename: '/admin',
    },
);

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>,
);
