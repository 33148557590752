import React, {useEffect, useState} from 'react';
import {getCookie} from 'library/cookie/get';
import {CookiesNames} from 'library/cookie/cookiesNames';
import {
    Button,
    Paper,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField,
} from '@mui/material';

interface UnmappedMoviesResponse {
    unmappedMovieLinks: {
        movieLink: string
    }[]
}

export const KinomaniakMappingMovieScreen = () => {
    const [unmappedMovies, setUnmappedMovies] = useState<
        UnmappedMoviesResponse['unmappedMovieLinks'] | undefined
    >(undefined);

    const [inputValueByMovieLink, setInputValueByMovieLink] = useState<Record<string, string>>({});

    const loadUnmappedMovies = async () => {
        setUnmappedMovies(undefined);
        setInputValueByMovieLink({});

        const response = await fetch(
            '/admin-api/cinema/kinomaniak-mapping/get-unmapped-movies',
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${getCookie(CookiesNames.JWT_KEY)}`,
                    'Content-Type': 'application/json',
                },
            },
        );
        const unmappedMoviesJson: UnmappedMoviesResponse = await response.json();
        setUnmappedMovies(unmappedMoviesJson.unmappedMovieLinks);
        setInputValueByMovieLink(
            Object.fromEntries(unmappedMoviesJson.unmappedMovieLinks.map(({movieLink}) => {
                return [movieLink, ''];
            })),
        );
    };

    const fetchUpdateMovie = async (movieLink: string, imdbId: string) => {
        const result = await fetch(
            '/admin-api/cinema/kinomaniak-mapping/save-movie-mapping',
            {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${getCookie(CookiesNames.JWT_KEY)}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({movieLink, imdbId}),
            },
        );

        const resultJson = await result.json();

        if (resultJson.error) {
            alert(resultJson.message);
        }

        loadUnmappedMovies();
    };

    const onInputChange = (movieLink: string, value: string) => {
        setInputValueByMovieLink((prevInputValue) => ({
            ...prevInputValue,
            [movieLink]: value,
        }));
    };

    const handleSubmit = (movieLink: string) => {
        const imdbId = inputValueByMovieLink[movieLink];

        if (!imdbId || imdbId === '') {
            alert(`ImdbId for ${movieLink} is empty`);
            return;
        }

        fetchUpdateMovie(movieLink, imdbId);
    };

    useEffect(() => {
        loadUnmappedMovies();
    }, []);

    if (unmappedMovies === undefined) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Movie link</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {unmappedMovies.map((unmappedMovieRow) => (
                            <TableRow
                                key={unmappedMovieRow.movieLink}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <a href={unmappedMovieRow.movieLink} target="_blank">
                                        {unmappedMovieRow.movieLink}
                                    </a>
                                </TableCell>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{display: 'flex', columnGap: '8px'}}
                                >
                                    <TextField
                                        required
                                        fullWidth
                                        label="ImdbID"
                                        name="imdbId"
                                        value={inputValueByMovieLink[unmappedMovieRow.movieLink]}
                                        onChange={(e) => onInputChange(unmappedMovieRow.movieLink, e.target.value)}
                                    />
                                    <Button
                                        variant="contained"
                                        onClick={() => handleSubmit(unmappedMovieRow.movieLink)}
                                    >
                                        Submit
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
