import React, {useEffect, useState} from 'react';
import {getCookie} from 'library/cookie/get';
import {CookiesNames} from 'library/cookie/cookiesNames';
import {
    FormControl, InputLabel, MenuItem,
    Paper, Select,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material';

interface UsersResponse {
    users: {
        id: number,
        username: string,
        isActive: boolean,
        wpSlug: string | null
    }[]
}

interface UserStatisticsResponse {
    data: {
        date: string,
        count: number
    }[]
}

export const UserProofreadDescriptionStatsScreen = () => {
    const [users, setUsers] = useState<UsersResponse['users'] | undefined>(undefined);
    const [selectedUserId, setSelectedUserId] = useState<number | undefined>(undefined);
    const [statisticsData, setStatisticsData] = useState<UserStatisticsResponse['data'] | undefined>(undefined);

    const loadAllUsers = async () => {
        const response = await fetch(
            '/admin-api/users/get-all',
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${getCookie(CookiesNames.JWT_KEY)}`,
                    'Content-Type': 'application/json',
                },
            },
        );
        const usersResponseJson: UsersResponse = await response.json();
        setUsers(usersResponseJson.users);
    };

    const onChangeUser = async (userId: number) => {
        setSelectedUserId(userId);

        const response = await fetch(
            `/admin-api/user-statistics/user-proof-read-statistics/get-by-user/${userId}`,
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${getCookie(CookiesNames.JWT_KEY)}`,
                    'Content-Type': 'application/json',
                },
            },
        );
        const userStatisticsResponseJson: UserStatisticsResponse = await response.json();
        setStatisticsData(userStatisticsResponseJson.data);
    };

    useEffect(() => {
        loadAllUsers();
    }, []);

    if (users === undefined) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <div style={{marginBottom: '32px'}}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">User</InputLabel>
                    <Select
                        labelId="user-id-select-label"
                        id="user-id-select"
                        value={selectedUserId}
                        label="User"
                        onChange={(e) => { onChangeUser(e.target.value as number); }}
                    >
                        {users.map((user) => (
                            <MenuItem value={user.id}>
                                {user.username}
                                {' '}
                                (wpSlug:
                                {' '}
                                {user.wpSlug}
                                )
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            {statisticsData !== undefined
                ? (
                    <div>
                        <span>
                            Last 30 days count:
                            {' '}
                            <strong>
                                {statisticsData.reduce((prev, curr) => {
                                    return prev + curr.count;
                                }, 0)}
                            </strong>
                        </span>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Count</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {statisticsData.map(({date, count}) => (
                                        <TableRow
                                            key={date}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {date}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {count}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )
                : null}
        </div>
    );
};
